import React, { useEffect, useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeMute } from "@fortawesome/free-solid-svg-icons";

import Header from "./components/Header";
import Card from "./components/Card";
import { Colors } from "./components/Colors";
import SearchColor from "./components/SearchColor";
import Popup from "./components/Popup";
import SearchNotFoundImg from "./images/search-not-found.png";
import music from "./alert.mp3";
let timeOut;
const App = () => {
  const [colors, setColors] = useState(Colors);
  const [popup, setPopup] = useState("");
  const [colorVal, setColorVal] = useState(null);
  const [message, setMessage] = useState("");
  const [search, setSearch] = useState("");
  const [audioPlaying, setAudioPlaying] = useState(true);
  const [btnSound, setBtnSound] = useState("");

  const soundOn = window.localStorage.getItem("soundOn");
  const messages = [
    "It'll Rock!",
    "Paste Me!",
    "Got It!",
    "Copied!",
    "Nice!",
    "Right One",
  ];

  const playAudio = () => {
    if (soundOn === "true") {
      const audio = new Audio(music);
      audio.play();
    }
  };

  const toggleAudioPlay = () => {
    if (audioPlaying === false) {
      setAudioPlaying(true);
      window.localStorage.setItem("soundOn", true);
      // setBtnSound(<FontAwesomeIcon icon={faVolumeHigh} />);
      setBtnSound("true");
    } else {
      setAudioPlaying(false);
      window.localStorage.setItem("soundOn", false);
      setBtnSound("false");
      // setBtnSound(<FontAwesomeIcon icon={faVolumeMute} />);
    }
  };

  const openPopup = (event) => {
    const currentColor = event.currentTarget.dataset.color;
    const randMessage = messages[Math.floor(Math.random() * messages.length)];

    setColorVal(currentColor);
    setPopup("active");
    setMessage(randMessage);
    navigator.clipboard.writeText(currentColor);
    playAudio();
  };

  const filteredColors = colors.filter((color) => {
    const { name, hex } = color;
    const searchLower = search.toLowerCase();
    return (
      name.toLowerCase().includes(searchLower) ||
      hex.toLowerCase().includes(searchLower)
    );
  });

  const searchColor = (event) => {
    const searchValue = event.target.value;

    setSearch(searchValue);
    // Debounce the filterColors function
    clearTimeout(timeOut);
    timeOut = setTimeout(() => filteredColors[searchValue], 300);
  };

  const hexToRgb = (hex) => {
    if (hex === null) return;
    hex = hex.replace("#", "");

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgb(${r}, ${g}, ${b})`;
  };

  useEffect(() => {
    setColors(Colors);
    if (window.localStorage.getItem("soundOn")) return;
    window.localStorage.setItem("soundOn", audioPlaying);
    // if (audioPlaying) {
    //   setBtnSound(<FontAwesomeIcon icon={faVolumeHigh} />);
    // } else {
    //   setBtnSound(<FontAwesomeIcon icon={faVolumeMute} />);
    // }
    if (audioPlaying) {
      setBtnSound("true");
    } else {
      setBtnSound("false");
    }
  }, [audioPlaying]);

  // useEffect(() => {
  //   const isSoundOn = window.localStorage.getItem("soundOn");
  //   if (isSoundOn === "true") {
  //     setBtnSound(<FontAwesomeIcon icon={faVolumeHigh} />);
  //   } else {
  //     setBtnSound(<FontAwesomeIcon icon={faVolumeMute} />);
  //   }
  // }, []);
  return (
    <>
      <Header
        toggleAudioPlay={toggleAudioPlay}
        btnSound={btnSound}
        setBtnSound={setBtnSound}
      />
      <div className="colors">
        {filteredColors?.length > 0 ? (
          filteredColors.map((color, idx) => (
            <Card
              key={idx}
              color={color}
              popupOpen={openPopup}
              isPlaying={audioPlaying}
              playAudio={playAudio}
            />
          ))
        ) : (
          <div className="no__match">
            <img src={SearchNotFoundImg} alt="Search not found" />
            <span>No colors found.</span>
          </div>
        )}
      </div>
      <SearchColor searchColor={searchColor} />
      <Popup
        showPopup={popup}
        setShowPopup={setPopup}
        colorValue={colorVal}
        colorRgb={hexToRgb(colorVal)}
        message={message}
      />
    </>
  );
};

export default App;
