import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faClose } from "@fortawesome/free-solid-svg-icons";
// showSearch,
// setShowSearch,
// showSearchModal,
const SearchColor = ({ searchColor }) => {
  const [searchIcon, setSearchIcon] = useState("search");
  const searchRef = useRef(null);
  const showSearchModal = () => {
    const searchContainer = searchRef.current;

    if (!searchContainer.classList.contains("active")) {
      setSearchIcon("close");
      searchContainer.classList.add("active");
      document.body.classList.add("s-active");
    } else {
      setSearchIcon("search");
      searchContainer.classList.remove("active");
      document.body.classList.remove("s-active");
    }
  };

  return (
    <>
      <div className={`search__container`} ref={searchRef}>
        <div className="search__input">
          <input
            type="text"
            placeholder="Search color by hex or name..."
            onChange={searchColor}
          />
          <button>Search</button>
        </div>
      </div>
      <div className="search__icon" onClick={showSearchModal}>
        {searchIcon === "search" ? (
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        ) : (
          <FontAwesomeIcon icon={faClose} />
        )}
      </div>
    </>
  );
};

export default SearchColor;
