import React, { useEffect } from "react";

const Popup = ({ showPopup, setShowPopup, colorValue, colorRgb, message }) => {
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowPopup("");
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [showPopup, setShowPopup]);
  return (
    <div className={`popup ${showPopup}`} style={{ backgroundColor: colorRgb }}>
      <div className="message">{message}</div>
      <div className="color__value">{colorValue}</div>
    </div>
  );
};

export default Popup;
