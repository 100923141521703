import React from "react";

const Card = ({ color, popupOpen }) => {
  return (
    <div
      className="color"
      style={{ backgroundColor: color.hex }}
      data-color={color.hex}
      onClick={popupOpen}
    >
      <button className="copy__btn">Copy</button>
      <span className="color__name">{color.name}</span>
    </div>
  );
};

export default Card;
