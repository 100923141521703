import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeMute } from "@fortawesome/free-solid-svg-icons";

const Header = ({ toggleAudioPlay, btnSound, setBtnSound }) => {
  useEffect(() => {
    const isSoundOn = window.localStorage.getItem("soundOn");
    // if (isSoundOn === "true") {
    //   setBtnSound(<FontAwesomeIcon icon={faVolumeHigh} />);
    // } else {
    //   setBtnSound(<FontAwesomeIcon icon={faVolumeMute} />);
    // }
    if (isSoundOn === "true") {
      setBtnSound("true");
    } else {
      setBtnSound("false");
    }
  }, [setBtnSound]);

  return (
    <div className="header">
      <div className="header__title">
        Color<span>Lab</span>
      </div>
      <div className="btns">
        <button className="btn__volume" onClick={toggleAudioPlay}>
          {btnSound === "true" ? (
            <>
              <span>Sound On</span>
              <div className="icon">
                <FontAwesomeIcon icon={faVolumeHigh} />
              </div>
            </>
          ) : (
            <>
              <span>Sound Off</span>
              <div className="icon">
                <FontAwesomeIcon icon={faVolumeMute} />
              </div>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Header;

// {isSoundOn === "true" ? (
//     <>
//       <span>Sound On</span>
//       <div className="icon">
//         <FontAwesomeIcon icon={faVolumeHigh} />
//       </div>
//     </>
//   ) : (
//     <>
//       <span>Sound Off</span>
//       <div className="icon">
//         <FontAwesomeIcon icon={faVolumeMute} />
//       </div>
//     </>
//   )}
